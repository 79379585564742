exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bezpieczenstwo-index-tsx": () => import("./../../../src/pages/bezpieczenstwo/index.tsx" /* webpackChunkName: "component---src-pages-bezpieczenstwo-index-tsx" */),
  "component---src-pages-doradztwo-bik-index-tsx": () => import("./../../../src/pages/doradztwo/bik/index.tsx" /* webpackChunkName: "component---src-pages-doradztwo-bik-index-tsx" */),
  "component---src-pages-doradztwo-doradztwo-podatkowe-index-tsx": () => import("./../../../src/pages/doradztwo/doradztwo-podatkowe/index.tsx" /* webpackChunkName: "component---src-pages-doradztwo-doradztwo-podatkowe-index-tsx" */),
  "component---src-pages-doradztwo-doradztwo-prawne-index-tsx": () => import("./../../../src/pages/doradztwo/doradztwo-prawne/index.tsx" /* webpackChunkName: "component---src-pages-doradztwo-doradztwo-prawne-index-tsx" */),
  "component---src-pages-doradztwo-index-tsx": () => import("./../../../src/pages/doradztwo/index.tsx" /* webpackChunkName: "component---src-pages-doradztwo-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klient-firmowy-index-tsx": () => import("./../../../src/pages/klient-firmowy/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-index-tsx" */),
  "component---src-pages-klient-firmowy-kredyt-celowy-index-tsx": () => import("./../../../src/pages/klient-firmowy/kredyt-celowy/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-kredyt-celowy-index-tsx" */),
  "component---src-pages-klient-firmowy-kredyty-dla-przedsiebiorstw-index-tsx": () => import("./../../../src/pages/klient-firmowy/kredyty-dla-przedsiebiorstw/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-kredyty-dla-przedsiebiorstw-index-tsx" */),
  "component---src-pages-klient-firmowy-kredyty-dla-wolnych-zawodow-index-tsx": () => import("./../../../src/pages/klient-firmowy/kredyty-dla-wolnych-zawodow/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-kredyty-dla-wolnych-zawodow-index-tsx" */),
  "component---src-pages-klient-firmowy-leasing-index-tsx": () => import("./../../../src/pages/klient-firmowy/leasing/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-leasing-index-tsx" */),
  "component---src-pages-klient-firmowy-ubezpieczenia-index-tsx": () => import("./../../../src/pages/klient-firmowy/ubezpieczenia/index.tsx" /* webpackChunkName: "component---src-pages-klient-firmowy-ubezpieczenia-index-tsx" */),
  "component---src-pages-klient-indywidualny-index-tsx": () => import("./../../../src/pages/klient-indywidualny/index.tsx" /* webpackChunkName: "component---src-pages-klient-indywidualny-index-tsx" */),
  "component---src-pages-klient-indywidualny-kredyty-gotowkowe-tsx": () => import("./../../../src/pages/klient-indywidualny/kredyty-gotowkowe.tsx" /* webpackChunkName: "component---src-pages-klient-indywidualny-kredyty-gotowkowe-tsx" */),
  "component---src-pages-klient-indywidualny-kredyty-hipoteczne-tsx": () => import("./../../../src/pages/klient-indywidualny/kredyty-hipoteczne.tsx" /* webpackChunkName: "component---src-pages-klient-indywidualny-kredyty-hipoteczne-tsx" */),
  "component---src-pages-klient-indywidualny-ubezpieczenia-tsx": () => import("./../../../src/pages/klient-indywidualny/ubezpieczenia.tsx" /* webpackChunkName: "component---src-pages-klient-indywidualny-ubezpieczenia-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o-nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-testimonial-slider-tsx": () => import("./../../../src/pages/TestimonialSlider.tsx" /* webpackChunkName: "component---src-pages-testimonial-slider-tsx" */),
  "component---src-pages-warto-wiedziec-index-tsx": () => import("./../../../src/pages/warto-wiedziec/index.tsx" /* webpackChunkName: "component---src-pages-warto-wiedziec-index-tsx" */),
  "component---src-pages-warto-wiedziec-kredyt-dla-wolnych-zawodow-tsx": () => import("./../../../src/pages/warto-wiedziec/kredyt-dla-wolnych-zawodow.tsx" /* webpackChunkName: "component---src-pages-warto-wiedziec-kredyt-dla-wolnych-zawodow-tsx" */),
  "component---src-pages-warto-wiedziec-leasing-dla-firm-tsx": () => import("./../../../src/pages/warto-wiedziec/leasing-dla-firm.tsx" /* webpackChunkName: "component---src-pages-warto-wiedziec-leasing-dla-firm-tsx" */),
  "component---src-pages-warto-wiedziec-leasing-finansowy-czy-operacyjny-tsx": () => import("./../../../src/pages/warto-wiedziec/leasing-finansowy-czy-operacyjny.tsx" /* webpackChunkName: "component---src-pages-warto-wiedziec-leasing-finansowy-czy-operacyjny-tsx" */),
  "component---src-pages-wspolpraca-biura-rachunkowe-tsx": () => import("./../../../src/pages/wspolpraca/biura-rachunkowe.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-biura-rachunkowe-tsx" */),
  "component---src-pages-wspolpraca-firmy-z-branzy-oze-tsx": () => import("./../../../src/pages/wspolpraca/firmy-z-branzy-oze.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-firmy-z-branzy-oze-tsx" */),
  "component---src-pages-wspolpraca-posrednictwo-nieruchomosci-tsx": () => import("./../../../src/pages/wspolpraca/posrednictwo-nieruchomosci.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-posrednictwo-nieruchomosci-tsx" */)
}

